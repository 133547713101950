import BaseModel from '../../models/BaseModel'

export interface ISlackIntergrate {
  _id?: string
  projectId?: string
  channel?: string
}

export class SlackIntergrationModel extends BaseModel {
  _id?: string
  projectId?: string
  channel?: string

  sertialize(): ISlackIntergrate {
    return {
      _id: this._id,
      projectId: this.projectId,
      channel: this.channel,
    }
  }
}
