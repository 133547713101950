
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { Watch } from 'vue-property-decorator'
import { APP_ROUTER_STORE_KEY, APP_USER_STORE_KEY, SLACK_AUTH_URL } from '@/constants/vars'
import { localStore } from '@/utils/localstore'
import { SlackIntergrationModel } from '@/components/intergration/slack-intergration-model'
import { UserModel } from '@/components/user/user-model'

import IntergrationMixin from '@/components/intergration/mixins/IntergrationMixin.vue'
import Empty from '@/components/common/ui/Empty.vue'
import cloneDeep from 'lodash/cloneDeep'
import { ProjectModel } from '@/components/project/project-model'

@Options({
  components: { Empty },
  directives: { maska },
})
export default class Intergration extends mixins(IntergrationMixin) {
  slackProjects: SlackIntergrationModel[] = []
  activeTab = 'slack'

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get ownProjects() {
    return this.$store.getters.ownProjects
  }

  get ownProjectsWithChanel() {
    const ownProjects = cloneDeep(this.ownProjects)
    return ownProjects.map((project: ProjectModel) => {
      const intergrateInfo = this.slackProjects.find((slack: SlackIntergrationModel) => slack.projectId === project._id)

      return { ...project, slackChannel: intergrateInfo?.channel, intergrateId: intergrateInfo?._id }
    })
  }

  get thisUrl() {
    const path = this.$router.resolve({
      name: 'workspace_board.intergration',
      params: this.$route.params,
    })?.fullPath

    return path
  }

  get redirectUrl() {
    const path = this.$router.resolve({
      name: 'redirect_slack',
    })?.fullPath

    return (location.origin + path).replace('http:', 'https:')
  }

  get slackCode() {
    return this.$route.query.code as string
  }

  @Watch('$route.params')
  routeChanged() {
    this.activeTab = (this.$route.params.service as string) || 'slack'
  }

  @Watch('activeTab', { immediate: true })
  @Watch('slackCode', { immediate: true })
  async activeTabChanged() {
    if (this.activeTab === 'slack' && !this.slackCode) {
      this.slackProjects = (await this.listSlack()) as SlackIntergrationModel[]
    }
  }

  @Watch('slackCode', { immediate: true })
  async slackCodeChanged(slackCode: string) {
    if (!slackCode) {
      return
    }

    this.$q.loading.show()

    const projectId = localStore.getItem(APP_USER_STORE_KEY.SELECTED_AUTH_SLACK_PROJECT_ID)
    if (projectId) {
      // [IntergrationMixin]
      await this.addSlack(projectId, this.slackCode)
    } else {
      this.$q.notify({
        type: 'nagative',
        message: 'Something wrong when trying to intergration with slack',
      })
    }

    this.$goto('workspace_board.intergration', { service: 'slack' })

    this.$q.loading.hide()
  }

  openSlackAuth(projectId: string) {
    localStore.setItem(APP_USER_STORE_KEY.SELECTED_AUTH_SLACK_PROJECT_ID, projectId)
    localStore.setItem(APP_ROUTER_STORE_KEY.SLACK_REDIRECT_URL, this.thisUrl)

    location.href = `${SLACK_AUTH_URL}&client_id=${process.env.VUE_APP_SLACK_CLIENT_ID}&redirect_uri=${this.redirectUrl}`
  }

  async onDelete(id: string) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to remove this intergration?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        await this.removeSlack(id)
        // Fetch all again
        this.activeTabChanged()
      })
  }

  created() {
    this.$meta.setMeta({ title: ['Intergration'] })
  }
}
