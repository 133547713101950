import INTERGRATION_GQL from '@/components/intergration/slack-intergration-graphql'
import { ISlackIntergrate, SlackIntergrationModel } from '@/components/intergration/slack-intergration-model'
import { NormApi } from '../../api/norm'

export class SlackIntergrationApi extends NormApi<SlackIntergrationModel> {
  constructor() {
    super()
    this.setClassRef(SlackIntergrationModel)
  }

  list = async (): Promise<ISlackIntergrate[] | null> => {
    return this.query({
      query: INTERGRATION_GQL.LIST_QUERY,
    })
  }

  add = async (projectId: string, code: string): Promise<SlackIntergrationModel | null> => {
    return this.mutation({
      query: INTERGRATION_GQL.ADD_MUTATION,
      variables: {
        projectId,
        code,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  remove = async (id: string): Promise<SlackIntergrationModel | null> => {
    return this.mutation({
      query: INTERGRATION_GQL.DELETE_MUTATION,
      variables: {
        id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }
}

export default new SlackIntergrationApi()
