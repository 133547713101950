
import { Vue, Options } from 'vue-class-component'
import SlackIntergrationApi from '@/components/intergration/slack-intergration-api'

@Options({
  components: {},
})
export default class IntergrationMixin extends Vue {
  get isAuth() {
    return this.$store.getters.isAuth
  }

  listSlack() {
    return SlackIntergrationApi.list()
  }

  addSlack(projectId: string, code: string) {
    return SlackIntergrationApi.add(projectId, code)
  }

  removeSlack(id: string) {
    return SlackIntergrationApi.remove(id)
  }
}
