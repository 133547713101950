import gql from 'graphql-tag'

const baseReturn = `
  _id
  projectId
  channel
`

const SLACK_INTERGRATION_GQL = {
  LIST_QUERY: gql`
    query slack_intergration_list {
      slack_intergration_list {
        ${baseReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation slack_intergration_add($projectId: String!, $code: String!) {
      slack_intergration_add(projectId: $projectId, code: $code) {
        ${baseReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation slack_intergration_delete($id: String!) {
      slack_intergration_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
}

export default SLACK_INTERGRATION_GQL
