import { render } from "./Intergration.vue?vue&type=template&id=9156dd4c"
import script from "./Intergration.vue?vue&type=script&lang=ts"
export * from "./Intergration.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QList,QItem,QItemSection,QItemLabel,QIcon,QBtn});
